import { TextField, IconButton, Grid, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useStyles } from '../../style/styles'
import { styled } from '@mui/material/styles'
// changed to import * as utils
import { createChannel, createChannelMembership, createUserArn, listChannelMessages, sendChannelMessage, updateChannelMarkerBackend, updateReadMarker } from '../../lib/messageUtils'
import { IProps } from './types'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { MessagingConstants } from '../../lib/enums'
import { setChannelData, updateChannelArn, updateChannelMessageList } from '../../redux/channel/actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/channel/types'
import * as utils from '../../lib/messageUtils'
import ReEnablePrompt from '../ReEnableChatPrompt'

const ChatInput = (props: IProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const channelsReducer = useSelector((state: RootState) => state.channels.channels)
  const [message, setMessage] = useState('')
  const textInputRef = useRef<HTMLInputElement>(null);
  const [textInputFocused, setTextInputFocused] = useState(false)

  const [textareaHeight, setTextareaHeight] = useState('38px')
  const { channel, receiver, sender, job, connections, platform, adminID, endOrReenableActionDoer, updatedChannel} = props
  const { channelArn, channelName, metadata, isEndedConversation } = channel
  const isRecruiter = (sender.role?.toUpperCase() === MessagingConstants.RECRUITER)
  const [isSelf, setIsSelf] = useState<boolean>(false)
  const [inputHeight, setInputHeight] = useState('auto')
  const inputRef = useRef<HTMLDivElement>(null);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false)
  const [isTextFieldDisabled, setIsTextFieldDisabled] = useState<boolean>(false)
  const [isSendDisabled, setIsSendDisabled] = useState<boolean>(false)
  const [isMessageNotEmpty, setIsMessageNotEmpty] = useState<boolean>(true)
  // const [isEndOrReenabledActionDoer, setIsEndOrReenabledActionDoer] = useState<boolean>(endOrReenableActionDoer == sender.userArn ? true : false)

  const DrawerCornerRadius = styled(SwipeableDrawer)(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: '8px',
    },
  }));;

  var isEndOrReenabledActionDoer = endOrReenableActionDoer == sender.userArn ? true : false

  useEffect(() => {
    if(connections.isBlocked || connections.isBlockedLink) {
      console.log('Chat is blocked from connections', connections)
      setIsTextFieldDisabled(true)
      setIsSendDisabled(true)
    }
    else{
      if (updatedChannel && updatedChannel?.Metadata) {
        console.log('Chat input has metadata')
        setIsMessageNotEmpty(message != null && message.trim().length > 0)
        const parsedMetadata = updatedChannel?.Metadata ? JSON.parse(updatedChannel?.Metadata) : null
        isEndOrReenabledActionDoer = parsedMetadata.EndOrReenableActionDoer == sender.userArn ? true : false

        if (parsedMetadata?.BlockedBy && parsedMetadata.BlockedBy.trim() !== '') {
          setIsTextFieldDisabled(true)
          setIsSendDisabled(true)
        } else {
          if(isEndedConversation) {
            if(isEndOrReenabledActionDoer) {
              console.log('Chat has been ended by user')
              setIsTextFieldDisabled(false)
              if(message != null && message.trim().length > 0) {
                console.log('Chat message is empty')
                 setIsSendDisabled(false)
               } else {
                console.log('Chat message checking is good')
                setIsSendDisabled(true)
              }
            } else {
              setIsTextFieldDisabled(true)
              setIsSendDisabled(true)
            }
          } else {
            setIsTextFieldDisabled(false)
            if(message != null && message.trim().length > 0) {
             console.log('Chat message is empty')
              setIsSendDisabled(false)
            } else {
             console.log('Chat message checking is good')
             setIsSendDisabled(true)
           }
          }
        }
      } else {
        console.log('Chat does not have metadata')
        if(message != null && message.trim().length > 0) {
          console.log('Chat message is empty')
          setIsSendDisabled(false)
        } else {
          setIsSendDisabled(true)
        }
        setIsTextFieldDisabled(false)
      }
    }
  }, [connections, metadata, updatedChannel, message])

  useEffect(() => {
    if(channelsReducer && channelsReducer[channelName]) {
      let messageListTemp = channelsReducer[channelName]?.messageList
      setIsSelf(utils.checkIsLastMessageSender(messageListTemp, sender.userArn))
    }
  }, [channelsReducer[channelName]?.messageList])
  // , [channelsReducer[channelName], channelsReducer[channelName]?.messageList])

  const checkMessageSubmission = (e: any) => {
    e.preventDefault();

    if (isEndedConversation && isEndOrReenabledActionDoer) {
      setIsPromptModalOpen(true); 
      console.log('Showing Re-Enable Chat Prompt modal');
    } else {
      setIsPromptModalOpen(false);
      if(isMessageNotEmpty) {
        handleNewMessageSubmit(e);
      }
      console.log('Proceeding with message submission');
    }
  };

  const handleNewMessageSubmit = async (e?: any) => {
    if (e && typeof e.preventDefault === 'function') e.preventDefault();
    const trimmedMessage = message.trim()
    setMessage('')
    if(platform !== 'mobile') {
      document.documentElement.style.setProperty('--default-input-height', '45px');
    }
    e.preventDefault()
    let sentMessages = null
    let receiverData = { externalUserId: receiver.externalUserId, messagingId: receiver.userArn, role: receiver.role }
    const senderData = { externalUserId: sender.externalUserId, messagingId: sender.userArn, role: sender.role }
    
    /* IF channel is not existing: CREATE NEW CHANNEL */
      if (!channelArn || channelArn === '') {
        try {
          let receiverArn = receiver.userArn
          if(!receiverArn) {
            receiverArn = await createUserArn(receiver)
            receiverData.messagingId = receiverArn
          }
          if(receiverArn) {
            const newChannel = await createChannel(channelName, job, receiverData, senderData)
            const newChannelArn = newChannel.channelArn
            const newChannelMetadata = JSON.stringify(newChannel.metadata)
            dispatch(updateChannelArn(channelName, newChannelArn))
            await createChannelMembership(receiverArn, newChannelArn)
            sentMessages = await sendChannelMessage(senderData, receiverData, newChannelArn, trimmedMessage)
            // update channel marker after sending FIRST message
            if(sentMessages && newChannelArn && newChannelArn !== '') {
              updateReadMarker(sender.userArn, newChannelArn, isSelf)
              window.parent.postMessage({ type: 'HANDLE_NEW_CHANNEL', jobseekerId: receiver.externalUserId, jobId: job.job_id, channelArn: newChannelArn }, '*');
              /* DISABLED FEATURE TJSKR-1572: update terms of service agreed after sending first message */
              /* utils.updateTermsOfServiceAgreed(sender.userArn)
                window.parent.postMessage({ type: MessagingConstants.TERMS_OF_SERVICE_ACCEPTED, userArn: sender.userArn }, '*'); */
            }
          }
      } catch (err) {
        alert(`Unable to create user due to some missing fields`)
      }}

    /* for existing channels */
    else {
      if (isEndedConversation) {
        try {
          await updateChannelMarkerBackend(channelArn, sender.userArn, adminID, MessagingConstants.REENABLE_CONVERSATION_MARKER, null)
          await updateReadMarker(sender.userArn, channelArn, isSelf)
          window.parent.postMessage({ type: MessagingConstants.REENABLE_CONVERSATION_MARKER, jobseekerId: receiver.externalUserId, jobId: job.job_id }, '*');
        } catch (err) { throw err }
      }
      if (isMessageNotEmpty && channelArn) {
        try {
          sentMessages = await sendChannelMessage(senderData, receiverData, channelArn, trimmedMessage)
          // update channel marker after sending message
          if(sentMessages && channelArn && channelArn !== '') {
            updateReadMarker(sender.userArn, channelArn, isSelf)
          }
        } catch (err) { throw err }
      }
    }

    /*  Code here to send push notification */
    const convertedNotifMessage = trimmedMessage.replaceAll('\n', '\\n')
    let gcm = `{ \"notification\": { \"body\": \"${convertedNotifMessage}\", \"title\": \"New Message from ${sender?.info?.first_name}\" }, \"data\": { \"recruiterId\": \"${sender.externalUserId}\", \"recruiterName\": \"${sender?.info?.first_name} ${sender?.info?.last_name}\", \"jobseekerId\": \"${receiver.externalUserId}\", \"position\": \"${job.position}\", \"company\": \"${job.company}\", \"jobId\": \"${job.job_id}\", \"recruiterEmail\": \"${sender?.info?.email}\" } }`
    await utils.sendPushNotification(receiver.userArn, '', gcm)

    textInputRef.current?.focus();
    console.log('Message successfully submitted!')
  }

  const handlePromptAction = async (accepted: boolean) => {
    setIsPromptModalOpen(false); // Close the modal
    if (accepted) {
       // User accepted the Re-Enable Chat Prompt, proceeding with message submission
      const trimmedMessage = message.trim()
      await updateChannelMarkerBackend(channelArn, sender.userArn, adminID, MessagingConstants.REENABLE_CONVERSATION_MARKER, null);
      await sendMessage(trimmedMessage)
       /*  Code here to send push notification */
      const convertedNotifMessage = trimmedMessage.replaceAll('\n', '\\n')
      let gcm = `{ \"notification\": { \"body\": \"${convertedNotifMessage}\", \"title\": \"New Message from ${sender?.info?.first_name}\" }, \"data\": { \"recruiterId\": \"${sender.externalUserId}\", \"recruiterName\": \"${sender?.info?.first_name} ${sender?.info?.last_name}\", \"jobseekerId\": \"${receiver.externalUserId}\", \"position\": \"${job.position}\", \"company\": \"${job.company}\", \"jobId\": \"${job.job_id}\", \"recruiterEmail\": \"${sender?.info?.email}\" } }`
      await utils.sendPushNotification(receiver.userArn, '', gcm)
    }

    setMessage(''); // Clear the message input
  }
    const sendMessage = async (text: string) => {
      setMessage('')
      await sendChannelMessage(
        { ...sender, messagingId: sender.userArn },
        { ...receiver, messagingId: receiver.userArn },
        channelArn!,
        text
      )
  };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      // Allow typing without showing the prompt if the user ended the conversation
      setMessage(event.target.value);
      if (platform !== 'mobile') {
        updateInputHeight();
      }
    };

  const handleFocus = () => {
    props.onFocus()
    setTextInputFocused(true)
    if(channelArn && channelArn !== '') {
      updateReadMarker(sender.userArn, channelArn, isSelf)
    }
  }

  const updateInputHeight = () => {
    const inputHeight = textInputRef.current?.offsetHeight;
    if (textInputRef) {
      document.documentElement.style.setProperty('--default-input-height', `${inputHeight}px`);
    }
  }

  return (
    <div className={platform == 'mobile' ? classes.chatInput : classes.chatInputWeb}>
      {platform == 'mobile' ? 
        <Grid
        container
        direction='row'
        height='inherit'
        justifyContent='flex-start'
        padding='8px 24px 8px 24px'
        alignItems="center"
        spacing={0}
        width='100%'
        sx={{ backgroundColor: 'white' }}
      >
        <Box
          sx={{
            borderRadius: '50px',
            overflow: 'hidden',
            border: '1px solid #8CB6EB',
            display: 'flex', // Ensures the Box wraps the content tightly
            alignItems: 'center', // Vertically center the content
            width: '100%',
            backgroundColor: 'white',
            // padding: '1px',
          }}
        >
        <Grid item xs={true}>
          <TextField
            variant="outlined"
            sx={{
              boxSizing: 'border-box',
              width: '100%',
              height: '30px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
            autoFocus
            fullWidth
            ref={textInputRef}
            placeholder="Write a Message"
            value={message}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={(e) => {setTextInputFocused(false)}}
            InputProps={{
              style: { height: textareaHeight, padding: '4px', whiteSpace: 'pre-line' },
            }}
            multiline
            minRows={1}
            maxRows={2}
            disabled={isTextFieldDisabled}
          />
        </Grid>
        
        <Grid item sx={{ padding: '0px 8px 0px 8px'}}>
          <IconButton sx={{padding:'10px'}}
            color="primary"
            onClick={checkMessageSubmission}
            disabled={isSendDisabled}
          >
          <img
            src={isSendDisabled ? "/images/Send-disabled.svg" : "/images/Send.svg"}
            alt="Send messsage"
            width={22}
            height={22}
          />
        </IconButton>
        </Grid>
        </Box>
      </Grid>
      :
      <Grid
        container
        direction='row'
        height='inherit'
        justifyContent='flex-start'
        alignItems="center"
        padding='8px 24px 8px 24px'
        width='100%'
        sx={{ position: 'relative' }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '5px',
            width: '95%',
            padding: '5px',
            display: 'flex'
          }}
        >
          <TextField
            variant="outlined"
            sx={{
              boxSizing: 'border-box',
              width: '100%',
              borderRadius: '5px',
              resize: 'none',
              backgroundColor: 'white'
            }}
            autoFocus
            fullWidth
            ref={textInputRef}
            placeholder="Write a Message"
            value={message}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={() => setTextInputFocused(false)}
            InputProps={{
              style: {
                minHeight: '45px',
                whiteSpace: 'pre-line',
              },
            }}
            multiline
            minRows={1}
            maxRows={3}
            disabled={isTextFieldDisabled}
          />
          <Grid item sx={{ padding: '0px 8px 0px 8px', display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              sx={{ padding: '0px' }}
              color="primary"
              onClick={checkMessageSubmission}
              disabled={isSendDisabled}
            >
              <img
                src={
                  isSendDisabled ?
                  "/images/Send-disabled.svg" :
                  "/images/Send.svg"
                }
                alt="Send message"
                width={22}
                height={22}
              />
            </IconButton>
          </Grid>
        </Box>
      </Grid>
      }
      {/* add condition to only render when isPromptOptn is true */}
      { isPromptModalOpen
        ? <ReEnablePrompt openPopIn={true} handleResponse={handlePromptAction} isEndedConversation={isEndedConversation} />
        : null
      }
    </div>
  )
}
                   
export default ChatInput