import { MessagePayload } from '../../components/Chat/types'
import { ChannelDetails, ChannelInfo } from './types'

export const setChannelData = (data: ChannelInfo) => ({
  type: 'SET_CHANNEL_DATA',
  payload: data
})

export const updateChannelArn = (channelName: string, channelArn: string) => {
  return {
    type: 'UPDATE_CHANNEL_ARN',
    payload: { channelName, channelArn }
  }
}

export const updateChannelMessageList = (channelArn: string, messageList: MessagePayload[]) => {
  return {
    type: 'UPDATE_MESSAGE_LIST',
    payload: {channelArn, messageList}
  }
}

export const updateTermsAgreed = (channelName: string, termsAgreed: boolean) => ({
  type: 'UPDATE_TERMS_AGREED',
  payload: { channelName, termsAgreed }
})

// specific for Quick Connect Refactor
export const setChannelDetails = (data: ChannelDetails) => ({
  type: 'SET_CHANNEL_DETAILS',
  payload: data
})
