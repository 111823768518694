export default {
  styleOverrides: {
    paper: {
      '& .MuiList-root': {
        padding: '4px 1px',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontStyle: 'inherit',
        width: '330px'
      },
      '& .MuiMenuItem-root': {
        padding: '8px 16px'
      }
    }
  }
}
