import { Container, Typography, Button, Link, Box, ThemeProvider, useMediaQuery } from '@mui/material'
import theme from "../../theme"
import { IProps } from './types'
import { useEffect, useState } from 'react';
import { MessagingConstants } from '../../lib/enums';
import { requestNewQuickConnectAccess } from '../../lib/messageUtils';

const InvalidAccess = (props: IProps) => {
    const { accessError } = props
    const url = window.location.href;
    const urlObj = new URL(url);
    const token =  urlObj.searchParams.get("token") || ""
    const deviceType =  urlObj.searchParams.get("device") || "web";
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); /* Check if it's a mobile view */
    const logoWidth = isMobile ? 200 : 280.5; /* default width for logo */
    const logoHeight = isMobile ? 41.5 : 58; /* default height for logo */
    const imageWidth = isMobile ? '90%' : 400; /* default width for no-search svg */
    const imageHeight = isMobile ? 'auto' : 400; /* default width for no-search svg */
    const fontSize = '24px';
    const [mainErrorMsg, setMainErrorMsg] = useState<string>("")
    const [subText, setSubText] = useState<string>("")
    const [showInitialRequest, setShowInitialRequest] = useState<boolean>(true)
    const [hasError, setHasError] = useState<boolean>(false)
    const [emailAccessText, setEmailAccessText] = useState<string>("Send me a new access e-mail")
    const appLink = deviceType === 'web-ios' ? MessagingConstants.IOS_JS_APP_LINK
                  : deviceType === 'web-android' ? MessagingConstants.ANDROID_JS_APP_LINK
                  : MessagingConstants.WEB_JS_APP_LINK;

    useEffect(() => {
        if(accessError === "expired") {
            setMainErrorMsg('Your access link has expired')
            setSubText('To access your messages, register in the Jobseeker App or')
        } else {
            setMainErrorMsg('Your access link is invalid')
            setSubText('Unable to access this link')
        }
    }, [accessError])

    const requestNewAccess = () => {
        setEmailAccessText("Sending...")
        if(token.trim() === '') {
            setShowInitialRequest(false)
            setHasError(true)
            setEmailAccessText("Invalid token. Request access failed")
        }
        else {
            requestNewQuickConnectAccess(token).then((res) => {
                if(res) {
                    setShowInitialRequest(false)
                    setHasError(false)
                    setEmailAccessText("New access successfully sent to email")
                } else {
                    setShowInitialRequest(true)
                    setHasError(true)
                    setEmailAccessText("New access request failed. Try again?")
                }
            })
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"/>
            <Container sx={{
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '100vh',
                padding: isMobile ? '16px' : '24px',
                textAlign: 'center',
                minHeight: '100vh',
                marginY: 'auto',
                backgroundColor: 'white'
             }}>
                <img src={"/images/jt-logo.svg"} alt="Jobtarget" width={logoWidth} height={logoHeight} />
                <img src={"/images/no_search.svg"} alt="No-Search" width={imageWidth} height={imageHeight} style={{ marginTop: isMobile ? '16px' : '32px' }}/>
                <Typography align="center" sx={{ fontWeight: 500, fontSize: fontSize, marginTop: isMobile ? '16px' : '32px' }}>
                    { mainErrorMsg }
                </Typography>
                <Typography variant="body1" align="center" paragraph sx={{ color: '#666', padding: '0 20px', marginTop:'16px'  }}>
                   { subText }
                </Typography>
                { accessError == "expired" && (
                  showInitialRequest ? (
                    <div onClick={requestNewAccess}>
                        <Typography sx={{ color: hasError ? '#E97451' : 'primary.main', cursor: 'pointer'}}>
                            { emailAccessText}
                        </Typography>
                    </div>
                  ) : (
                    <div>
                        <Typography sx={{ color: hasError ? '#E97451' : 'primary.main'}}>
                            { emailAccessText }
                        </Typography>
                    </div>
                  )
                )}
                <a href={appLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="primary" sx={{ marginBottom: 2, marginTop:  '16px', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 300, textTransform: 'none'  }}>
                        Register with JobTarget Jobseeker
                    </Button>
                </a>
            </Container>
        </ThemeProvider>
    );
};

export default InvalidAccess
