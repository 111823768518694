export enum MessagingConstants {
    MESSAGING_CHAT = 'CHAT',
    MESSAGING_QUICK_CONNECT = 'QUICK_CONNECT',
    CANDIDATE = "CANDIDATE",
    END_CONVERSATION_MARKER = "end_conversation",
    RECRUITER = "RECRUITER",
    REENABLE_CONVERSATION_MARKER = "reenable_conversation",
    REMOVE_FOR_EVERYONE = "remove_for_everyone",
    REMOVE_FROM_SCREEN = "remove_from_screen",
    REMOVED_FOR_EVERYONE_TIMESTAMP = "removedForEveryoneTimeStamp",
    REMOVED_FROM_SCREEN_TIMESTAMP = "removedFromScreenTimestamp",
    UNDO_REMOVE_MESSAGE = "undoTimestamp",
    TERMS_OF_SERVICE_ACCEPTED = "TERMS_OF_SERVICE_ACCEPTED",
    TERMS_ACCEPTED_FROM_OTHER_CHAT = "TERMS_ACCEPTED_FROM_OTHER_CHAT",
    IOS_JS_APP_LINK = 'https://apps.apple.com/app/apple-store/id6651849817?utm_source=messaging_chat&utm_medium=quickconnect&utm_campaign=register_button&pt=125699475&ct=Unread%20Notification%201&mt=8',
    ANDROID_JS_APP_LINK = 'https://play.google.com/store/apps/details?id=com.jobtarget.jobseeker&utm_source=messaging_chat&utm_medium=quickconnect&utm_campaign=register_button',
    WEB_JS_APP_LINK = 'https://jobseeker.jobtarget.com?utm_source=messaging_chat&utm_medium=quickconnect&utm_campaign=register_button'
}