const getDisplayName = (user: any, termsOfServiceAgreed: boolean) => {
    const { first_name, last_name, email, role } = user
    if( !first_name || first_name.trim().length === 0 || !last_name || last_name.trim().length === 0 ) {
      return '-'
    } else {
      let username = `${first_name} ${last_name}`
      // if(role =="Recruiter" || (role =="Jobseeker" && termsOfServiceAgreed)) {
      if(username && username.trim() !== '') return username
      else return email?.split('@')[0]
    }
  }
export default getDisplayName