import axios from 'axios'
 
const apiURL = process.env.REACT_APP_MESSAGING_API
const jobseekerApiURL = process.env.REACT_APP_CONNECTIONS_API
export const environment = process.env.REACT_APP_ENV
 
export const axiosInstance = axios.create({
  baseURL: apiURL,
});

export const jobseekerAxiosInstance = axios.create({
  baseURL: jobseekerApiURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const axiosInstances = {
  axiosInstance,
  jobseekerAxiosInstance
};

export default axiosInstances;
