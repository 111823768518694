

import React from "react";
import { IProps } from "./types";
import { useStyles } from "../../style/styles";
import { IconButton, useMediaQuery } from "@mui/material";

const JobDescription  = (props: IProps) => {
  const classes = useStyles();
  const { platform, apply_date, job, handleClose } = props
  const isMobile = useMediaQuery("(max-width: 700px)") || platform === "mobile"

  return (
    <>
     <style>
        {`
          @keyframes slideUp {
            from {
              transform: translateY(100%);
            }
            to {
              transform: translateY(0);
            }
          }
        `}
      </style>
      <div className={isMobile ? classes.jobModal : ''}>
        <div className={classes.jobModalContent}>
          <div className={classes.jobDescriptionHeader}>
            <p className={classes.jobDescriptionMainTitle}>Job Details</p>
              { isMobile &&
                <IconButton onClick={handleClose} sx={{ width: '38px', height: '38px', '&:hover': { cursor: 'pointer' }}} color="inherit" id="closeButton">
                  <img src="/images/close-gray.svg" alt="Close chat" width={18} height={20}/>
                </IconButton>
              }
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', padding: '16px 0px',  boxShadow: '0 2px 0px rgba(0, 0, 0, 0.1)'}}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '16px 0'}}>
                <p style={{ margin: 0, fontSize: '14px', fontWeight: '600'}}> { job?.title } </p>
                {/* NOTE: Hide company details if anonymous posting is true  */}
                { !job?.anonymousPosting && (
                  <>
                    <p style={{ margin: 0, fontSize: '14px'}}> { job?.companyName } </p>
                    <p style={{ margin: 0, fontSize: '14px'}}> { job?.locations } </p>
                  </>
                ) }
              </div>
              <p style={{ color: '#B5B5C3', margin: 0}}> { apply_date } </p>
            </div>
            <p className={classes.jobDescriptionSubTitle}>Job Summary</p>
            <div dangerouslySetInnerHTML={{ __html: job?.description }} style={{ padding: 0, fontSize: '14px' }} />
            {/* <p>Supervisory Responsibilities:</p>
            <ul style={{ margin: 0 }}>
              <li>None.</li>
            </ul>
            <p>Duties/Responsibilities:</p>
            <ul style={{ margin: 0 }}>
              <li>Identifies, investigates, and resolves users' problems with computer software and hardware.</li>
              <li>Fields support calls, chat, email, and other communication from users with inquiries regarding software programming, connectivity, printing, and similar concerns.</li>
              <li>Consults with users to determine steps and procedures taken to identify and resolve the problem.</li>
              <li>Applies knowledge of computer software, hardware, and procedures to solve problems.</li>
              <li>Guides users through diagnostic and troubleshooting processes, which may include use of diagnostic tools and software and/or following verbal instructions.</li>
              <li>Collaborates with other staff to research and resolve problems.</li>
              <li>Collaborates with programmers to explain errors and/or recommend modifications in programs.</li>
              <li>Arranges service by software or hardware vendors to repair or replace defective products.</li>
            </ul> */}
          </div>
        </div>
      </div>
    </>
  );
};


export default JobDescription;
