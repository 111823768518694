import { Route, Routes, createBrowserRouter } from 'react-router-dom'
import Health from '../pages/health'
import QuickConnect from '../pages/QuickConnect'
import MainPage from '../App'
import React from 'react';
import InvalidAccess from '../components-refactored/InvalidAccess';

const router = createBrowserRouter([
    {
      path: "/health",
      element: <Health/>,
    },
    {
      path:"/chat",
      element: <MainPage/>
    },
    {
      path:"/",
      element: <MainPage/>
    },
    {
      path:"/quick-connect",
      element: <QuickConnect/>
    },
    {
      path:"/quick-connect/invalid-access",
      element: <InvalidAccess accessError="invalid"/>
    },
    {
      path:"/quick-connect/expired-access",
      element: <InvalidAccess accessError="expired"/>
    },
  ]);
  
export default router