import React, { useEffect, useState } from "react";
import theme from "../../theme";
import { useStyles } from "../../style/styles";
import { Box, CircularProgress, Grid, ThemeProvider, Typography, useMediaQuery } from "@mui/material";
import JobDescription from "../../components/JobDescription";
import * as utils from '../../lib/messageUtils'
import Chat from "../../components-refactored/Chat";

import store from '../../redux/store'
import { Provider } from 'react-redux'
import InvalidAccess from "../../components-refactored/InvalidAccess";

const QuickConnect = () => {
  const classes = useStyles();
  const url = window.location.href;
  const urlObj = new URL(url);
  const platform = urlObj.searchParams.get("platform") || "web";
  const deviceType =  urlObj.searchParams.get("device") || "web";
  const token = urlObj.searchParams.get("token");
  const isMobile = useMediaQuery("(max-width: 700px)") || platform === "mobile";
  const [apply_date, setApplyDate] = useState<string | null>("")
  const [quickConnectData, setQuickConnectData] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isInvalidToken, setIsInvalidToken] = useState<boolean>(false)
  const [isExpiredToken, setIsExpiredToken] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    if(token) {
      utils.validateQuickConnectToken(token).then((res: any) => {
        if(res && res?.data && res !== 'expired' && res !== 'invalid') {
          const returnedQuickConnectData = res?.data
          setApplyDate(returnedQuickConnectData?.data?.date_applied)
          utils.formQuickConnectChatData(returnedQuickConnectData?.data).then((chatData) => {
            setQuickConnectData(chatData)
            setIsLoading(false)
         })
        }
        else {
          setIsLoading(false)
          if(res === 'expired') {
            setIsExpiredToken(true)
          } else {
            setIsInvalidToken(true)
          }
        }
      })
    }
  }, [token])

  return !isLoading ? (
    quickConnectData && !isInvalidToken && !isExpiredToken ? (
      <Provider store={store}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"/>
        <ThemeProvider theme={theme}></ThemeProvider>
        <div className={classes.quickConnectMainContainer}>
          {/* HEADER */}
          {/* <header className={classes.quickConnectHeader}>
            <div>
              <strong> JOBTARGET </strong>
            </div>
          </header> */}

          {/* QUICK CONNECT BODY */}
          <main
            className={`${classes.quickConnectBody} ${
              isMobile ? classes.quickConnectBodyMobile : ""
            }`}
          >
            {/* CHAT / MESSAGING COMPONENT */}
            <div className={classes.quickConnectChat}>
              <div>
                <Chat
                  sender={quickConnectData.sender}
                  receiver={quickConnectData.receiver}
                  channel={quickConnectData.channel}
                  job={quickConnectData.job}
                  token={quickConnectData.token}
                  connections={quickConnectData.connections}
                  platform={platform}
                  deviceType={deviceType}
                  apply_date={apply_date}
                />
              </div>
            </div>

            {/* JOB DESCRIPTION */}
            {!isMobile && (
              <div className={classes.quickConnectJobDescription}>
                <JobDescription
                  platform = "web"
                  apply_date={apply_date}
                  job = {quickConnectData?.job}
                />
              </div>
            )}
          </main>
        </div>
      </Provider>
    ):
    <div>
      { isExpiredToken ? (
        <InvalidAccess accessError="expired" />
      ) : (
        <InvalidAccess accessError="invalid" />
      )}
    </div>
  ) : (
    <div style={{background: 'white'}}>
      <div className={classes.centeredStyle}>
        <Box className={classes.centeredSpinner}>
          <CircularProgress size={40} thickness={5} color="primary" />
        </Box>
      </div>
    </div>
  )
};

export default QuickConnect;
